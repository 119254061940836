import React, {
	type PropsWithChildren,
	useContext,
	useEffect,
	useRef,
	useState,
	useCallback,
} from "react";
import type { MemberGateReason } from "../../components/MembershipGateContainer/MembershipGateContainer";
import { decrypt } from "../../utilities/crypto";
import { tryParseJson } from "../../utilities/stringUtils";
import { useMatomo } from "@datapunt/matomo-tracker-react";

declare global {
	interface Window {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		dataLayer: any[];
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		_mtm: any[];
	}
}

const dataLayerObject = {
	event: "userAuthenticated",
};

// Define the structure of the consent cookie
interface ConsentCookie {
	googleconsentmap: {
		ad_storage: string;
		analytics_storage: string;
		ad_user_data: string;
		ad_personalization: string;
		functionality_storage: string;
		personalization_storage: string;
		security_storage: string;
	};
	firstpage: string;
	action?: string;
	consenttime?: number;
	categories: string | string[];
}

// Define the event detail structure for consent events
interface ConsentEventDetail {
	categories: string | string[];
	googleconsentmap?: {
		ad_storage?: string;
		analytics_storage?: string;
		ad_user_data?: string;
		ad_personalization?: string;
		functionality_storage?: string;
		personalization_storage?: string;
		security_storage?: string;
	};
}

// Extend the Event interface to include our custom detail
interface ConsentEvent extends Event {
	detail: ConsentEventDetail;
}

export interface EncryptResponse {
	key?: string;
	reason?: MemberGateReason;
}
interface AuthContextState {
	isUserLoggedIn: () => Promise<boolean>;
	loginOrMyAccount: () => void;
	logout: () => void;

	getEncryptionKeyOrReason: (
		article: Queries.ArticleDataFragment,
	) => Promise<EncryptResponse>;
	decryptArticle: (
		key: string,
		article: Queries.ArticleDataFragment,
	) => Promise<false | EncryptedData>;
	parseNonEncryptedArticle: (
		article: Queries.ArticleDataFragment,
	) => EncryptedData;
	isLoggedIn: boolean;
}

const AuthContext = React.createContext<AuthContextState>(
	{} as AuthContextState,
);
const LOGGED_IN_TIMEOUT = 1000 * 60 * 30; // 5 minutes
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const loggedInTimeoutRef = useRef<NodeJS.Timeout | null>(null);





	const matomoUrl = "https://rusi.matomo.cloud";
	const siteId = 1;
	useEffect(() => {


		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
		(function() {
			var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
			g.async=true; g.src='https://cdn.matomo.cloud/rusi.matomo.cloud/container_TUTNkNpL.js'; s.parentNode.insertBefore(g,s);
		})();

	}, [siteId, matomoUrl]);


	/**
	 * Checks if the user is logged in.
	 * @returns Whether the user is logged in.
	 */
	const isUserLoggedIn = useCallback(async (): Promise<boolean> => {
		try {
			if (isLoggedIn) {
				return true;
			}
			if (process.env.GATSBY_LOGIN_AUTH_CHECK_URL) {
				// First we hit the my-account API to check if the user is logged in.
				const isLoggedIn = await fetch(
					process.env.GATSBY_LOGIN_AUTH_CHECK_URL,
					{
						credentials: "include",
						method: "GET",
						mode: "no-cors",
						redirect: "manual",
					},
				);

				loggedInTimeoutRef.current = setTimeout(
					() => setIsLoggedIn(false),
					LOGGED_IN_TIMEOUT,
				);
				setIsLoggedIn(isLoggedIn.ok);
				if (isLoggedIn.ok) {
					const { userId } = await isLoggedIn.json();

					if (typeof window !== "undefined") {
						let _paq = window._paq = window._paq || [];
						_paq.push(['setUserId', userId]);
					}


					// Google Tag Manager
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push(dataLayerObject);

					localStorage.removeItem("redirectPage");
				}
				return isLoggedIn.ok;
			}
			setIsLoggedIn(false);
			return false;
		} catch (error) {
			console.error(error);
			setIsLoggedIn(false);
			return false;
		}
	}, [isLoggedIn]);

	const logout = async () => {
		localStorage.setItem("redirectPage", window.location.href);
		await fetch(`${process.env.GATSBY_MY_ACCOUNT_LOGOUT_URL}`, {
			method: "GET",
		}).then(() => {
			window.location.href = `${process.env.GATSBY_LOGOUT_URL}`;
		});
	};
	const loginOrMyAccount = () => {
		if (isLoggedIn) {
			window.location.href = `${process.env.GATSBY_MY_DASHBOARD_URL}`;
		} else {
			localStorage.setItem("redirectPage", window.location.href);
			window.location.href =
				process.env.GATSBY_MY_ACCOUNT_URL || "/api/my-account/";
		}
	};

	useEffect(() => {

		if (typeof window !== "undefined") {
			window.addEventListener('CookieScriptLoaded', () => {
				console.log("CookieScript Loaded.");
				consentSet();
			});

			window.addEventListener('CookieScriptAcceptAll', () => {
				consentSet();
			});

			window.addEventListener('CookieScriptAccept', () => {
				consentSet();
			});

			window.addEventListener('CookieScriptReject', () => {
				consentSet();
			});
		}

		function consentSet() {
			let _paq = window._paq = window._paq || [];

			if (typeof window !== "undefined" && window.CookieScript?.instance) {
				const currentState = window.CookieScript.instance.currentState();
				if (currentState.categories?.includes("performance") && currentState.action === "accept") {
					console.log("cg");
					_paq.push(['rememberCookieConsentGiven'])
					_paq.push(['setCookieConsentGiven']);
				} else {
					console.log("cta");
					_paq.push(['forgetCookieConsentGiven']);
					_paq.push(['deleteCookies']);
				}
			}
		}

		return () => {
			// window.removeEventListener(
			// 	"CookieScriptConsentGiven",
			// 	consentGivenHandler,
			// );
			// window.removeEventListener(
			// 	"CookieScriptConsentRejected",
			// 	consentRejectedHandler,
			// );
		};
	}, []);

	useEffect(() => {
		const checkUserLoggedIn = async () => {
			const loggedIn = await isUserLoggedIn();
			setIsLoggedIn(loggedIn);
		};
		checkUserLoggedIn();

		return () => {
			if (loggedInTimeoutRef.current) {
				clearTimeout(loggedInTimeoutRef.current);
			}
		};
	}, []);

	/**
	 * Checks if the user can decrypt an article.
	 * @param article The article to check.
	 * @returns {key: string} The encryption key if the user can decrypt the article
	 * @returns {reason: string} A reason if the user cannot decrypt the article
	 */
	const getEncryptionKey = async (nodeId: string): Promise<EncryptResponse> => {
		try {
			if (!process.env.GATSBY_GATED_CONTENT_KEY_URL)
				return {
					reason: "error",
				};
			const res = await fetch(
				`${process.env.GATSBY_GATED_CONTENT_KEY_URL}/${nodeId}`,
				{
					credentials: "include",
					method: "GET",
					mode: "no-cors",
					redirect: "manual",
				},
			)
				.then((res) => res.json())
				.catch((err) => {
					console.log(err);
					return false;
				});

			// If we have the key it was a success
			if (res?.key) {
				return {
					key: res.key,
				};
			}
			// If we have a reason it was a failure
			return {
				reason: res?.reason,
			};
		} catch (error) {
			console.error(error);
			return {
				reason: "error",
			};
		}
	};

	function decryptEncryptedNode<T>(
		key: string,
		encryptedNode?: Queries.EncryptedNodeFragment,
		callback = (decrypted: string) => decrypted as T,
	) {
		if (!encryptedNode) return false;
		return callback(decrypt(key, encryptedNode.content));
	}

	/**
	 * 1. Checks if the user is logged in.
	 * 2. Checks if the user can get an encryption key for the article.
	 * 3. Validates the encryption key.
	 * 4. Returns either the encryption key or a reason why the user cannot decrypt the article.
	 * @param article - The article to check.
	 * @returns {key: string} The encryption key if the user can decrypt the article
	 * @returns {reason: string} A reason if the user cannot decrypt the article
	 */
	const getEncryptionKeyOrReason = async ({
		id,
		fields: { encrypted_validate, key: devKey },
	}: Queries.ArticleDataFragment): Promise<EncryptResponse> => {
		if (devKey) return { key: devKey };
		if (!isLoggedIn) {
			const checkLogin = await isUserLoggedIn();
			if (!checkLogin)
				return {
					reason: "no-account",
				};
		}
		let { key, reason } = await getEncryptionKey(id);

		if (
			key &&
			decryptEncryptedNode<string>(key, encrypted_validate) !== "valid"
		) {
			key = undefined;
			reason = "error";
		}
		return {
			key,
			reason,
		};
	};

	/**
	 * Decrypts an article, given an encryption key.
	 * The key should have been pre-validated so this should always return the decrypted article data
	 * @param key The encryption key.
	 * @param article The article to decrypt.
	 * @returns false if the article could not be decrypted
	 * @returns EncryptedData if the article was decrypted
	 */
	const decryptArticle = async (
		key: string,
		article: Queries.ArticleDataFragment,
	): Promise<false | EncryptedData> => {
		try {
			const { fields, encryptedSections, encryptedPdfs } = article;
			const body = decryptEncryptedNode<string>(key, fields?.encrypted_body);

			const taylorAndFrancisUrl = decryptEncryptedNode<string>(
				key,
				fields?.encrypted_taylor_and_francis_url,
			);
			type ExcludesFalse = <T>(x: T | null) => x is T;
			const sections: ParagraphFragment[] = (
				encryptedSections
					?.map(
						(encryptedSection: Queries.EncryptedNodeFragment) =>
							decryptEncryptedNode<string>(key, encryptedSection) || null,
					)
					// biome-ignore lint/suspicious/noExplicitAny: Required to filter out nulls
					.filter(Boolean as any as ExcludesFalse) || []
			).map(tryParseJson);

			const pdfUrl: Queries.PdfDataFragment[] = (
				encryptedPdfs
					?.map(
						(pdf: Queries.EncryptedNodeFragment) =>
							decryptEncryptedNode<string>(key, pdf) || null,
					)
					// biome-ignore lint/suspicious/noExplicitAny: Required to filter out nulls
					.filter(Boolean as any as ExcludesFalse) || []
			).map(tryParseJson);

			return {
				body,
				pdfUrl,
				taylorAndFrancisUrl,
				sections,
			};
		} catch (error) {
			console.log("error", error);
			return false;
		}
	};

	const parseNonEncryptedArticle = (
		article: Queries.ArticleDataFragment,
	): EncryptedData => {
		const contentOrFalse = (content?: string) =>
			content && content.length > 0 ? content : false;

		const { fields, encryptedSections, encryptedPdfs } = article;

		const sections: ParagraphFragment[] =
			encryptedSections?.map(
				(section) => section?.content?.length && tryParseJson(section.content),
			) || [];

		const pdfUrl: Queries.PdfDataFragment[] =
			encryptedPdfs?.map(
				(section) => section?.content?.length && tryParseJson(section.content),
			) || [];
		return {
			body: contentOrFalse(fields?.encrypted_body?.content),
			pdfUrl,
			taylorAndFrancisUrl: contentOrFalse(
				fields?.encrypted_taylor_and_francis_url?.content,
			),
			sections,
		};
	};
	return (
		<AuthContext.Provider
			value={{
				isUserLoggedIn,
				loginOrMyAccount,
				logout,
				getEncryptionKeyOrReason,
				decryptArticle,
				parseNonEncryptedArticle,
				isLoggedIn,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextState => useContext(AuthContext);
